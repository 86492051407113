@import '/src/storybook/styles/variables';

.header {
  background-color: white;
  padding-bottom: 37px;
  a {
    text-decoration: inherit;
    cursor: pointer;
  }
  .top-line {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;

    align-items: center;
    .documentation-link {
      font-weight: bold;
      padding: 20px;
      width: fit-content;
      color: white;
      border-radius: 40px;
      background-color: var(--primary-color);
      &:hover {
        background-color: var(--primary-color-hover);
      }
    }
  }
  .centered {
    cursor: default;
    text-align: center;
    .welcome-message {
      font-weight: bold;
      a {
        color: $link-color;
      }
    }
    .title {
      font-weight: bold;
      font-size: 38px;
      margin-top: 10px;
    }
    .colored {
      color: var(--primary-color);
      font-weight: bold;
    }
    .sub-title {
      margin-top: 8px;
      font-size: 20px;
    }
    p {
      &:first-of-type {
        margin-top: 20px;
      }
      color: rgba(0,0,0,.5);
      margin: 0px;
      .emphasized {
        color: black;
        font-weight: bold;
        &.documentation a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}